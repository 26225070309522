import { useIsAuthenticated } from "@azure/msal-react";
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { AppContext } from "../AppContext";
import './Header.css';
import HeaderMenu from "./HeaderMenu";
import Login from './LoginMenu';
const AnimatedMenuIcon = motion(MenuIcon);

const Header = () => {
    const isAuthenticated = useIsAuthenticated();
    const { menuItems } = useContext(AppContext);

    const styles = {
        header: {
            backgroundColor: !isAuthenticated ? 'transparent' : '#00594d',
            zIndex: 4,
            boxShadow: 'none',
            borderBottom: isAuthenticated ? '2px solid white' : '',
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '170px',
        },
        logoButton: {
            maxHeight: '50px',
            height: '40px',
            width: 'auto',
            opacity: 1,
            zIndex: 4
        },
        menuItems: {
            display: 'flex',
            gap: '20px',
        },
        menuLink: {
            color: 'white',
            textDecoration: 'none',
            fontWeight: '700',
            fontSize: '16px',
            textTransform: 'uppercase' as 'uppercase',
            fontFamily: "'Barlow', sans-serif",
            zIndex: 4,
            letterSpacing: 'normal',
        },
        languageSelector: {
            fontSize: '14px',
            color: 'white',
        },
        languageLink: {
            color: 'inherit',
            textDecoration: 'none',
            cursor: 'pointer',
        },
    };

    const menuIconStyles = isAuthenticated ? {
        color: 'white',
        height: "100%",
        ml: "9px",
        mr: "9px",
        cursor: 'pointer',
        outline: 'none'
    } : {
        color: '#0d4557',
        height: "100%",
        ml: "9px",
        mr: "9px",
        cursor: 'pointer',
        outline: 'none'
    }

    return (
        <AppBar position="fixed" style={styles.header}>
            <Toolbar style={styles.toolbar}>
                <IconButton edge="start" color="inherit" aria-label="logo">
                    <img
                        src="https://travelheartfamily.com/wp-content/uploads/2023/09/THF-LOGO-LOGOTYPE-neg.svg"
                        alt="Travelheart Family"
                        width="0"
                        height="0"
                        style={styles.logoButton}
                    />
                </IconButton>
                {isAuthenticated &&
                    <HeaderMenu items={menuItems} />
                }
                <Login />
            </Toolbar>
        </AppBar>
    );
};

export default Header;