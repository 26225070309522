
import { AuthenticationResult } from '@azure/msal-browser';
import { userPermissionScopes } from "../AuthConfig";
import {
    EmbedTokenResult,
    MangeUsersDTO,
    MangeUsersDTOV2,
    MenuItemDetails,
    PermissionRequest,
    User,
    UserReports,
    ValidationPermissionsRequest,
    ViewModel
} from '../models/models';
import { msalInstance } from './../index';

const ApiRoot: string = process.env.REACT_APP_API_BASE_URL;

const GetAccessToken = async (): Promise<string> => {
    const account = msalInstance?.getActiveAccount();

    if (account) {
        let authResult: AuthenticationResult;
        try {
            // try to acquire access token from MSAL cache first
            authResult = await msalInstance.acquireTokenSilent({ scopes: userPermissionScopes, account: account });
        }
        catch {
            // if access token not available in cache, interact with user to acquire new access token 
            authResult = await msalInstance.acquireTokenPopup({ scopes: userPermissionScopes, account: account });
        }
        // return access token from authnetication result 
        return authResult.accessToken;
    }
    else {
        return "";
    }
};

export const LoginUser = async (LoginId: string, UserName: string): Promise<void> => {

    var user = new User();
    user.LoginId = LoginId;
    user.UserName = UserName;

    var accessToken: string = await GetAccessToken();
    var postData: string = JSON.stringify(user);

    var restUrl = ApiRoot + "UserLogin/";

    await fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    })
    return;
}


export const GetEmbeddingData = async (): Promise<ViewModel> => {

    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "Embed/";

    return fetch(restUrl, {
        method: "GET",
        headers: {
            "Accept": "application/json;",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
}

export const GetEmbedToken = async (): Promise<EmbedTokenResult> => {

    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "EmbedToken/";

    return fetch(restUrl, {
        method: "GET",
        headers: {
            "Accept": "application/json;",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
}

export const GetUserReports = async (UserName: string): Promise<UserReports> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/";
    var user = new User();
    user.UserName = UserName;
    var postData: string = JSON.stringify(user);

    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then((response: UserReports) => {
            for (var i = 0; i < response.allReports.length; i++) {
                var rep = response.assignedReports.find(x => x.menuId === response.allReports[i].id);
                if (rep)
                    response.allReports[i]["isAssigned"] = true
                else
                    response.allReports[i]["isAssigned"] = false
            }
            return response
        });
    return fetchResponse;
}

export const UpdateUserReports = async (userName: string, assignedReportIds: string[]): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateReportMapping/" + userName;
    var permissionRequest = new PermissionRequest();
    permissionRequest.reports = assignedReportIds;
    var postData: string = JSON.stringify(permissionRequest);
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const IsValidUser = async (userName: string, permissionsToCheck: string[]): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/CheckUserPermissions/";
    var permissionRequest = new ValidationPermissionsRequest();
    permissionRequest.permissionsToCheck = permissionsToCheck;
    permissionRequest.userToCheck = userName;
    var postData: string = JSON.stringify(permissionRequest);
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const GetUsers = async (UserName?: string): Promise<MangeUsersDTO> => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/GetUsers";
        if (UserName)
            restUrl += "/" + UserName;

        const response = await fetch(restUrl, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        });

        if (!response.ok) {
            throw new Error("Failed to fetch user data.");
        }

        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error("Error fetching user data:", error);
    }
}

export const GetMenuItems = async (): Promise<MenuItemDetails> => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/GetMenuItems";

        const response = await fetch(restUrl, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        });

        if (!response.ok) {
            throw new Error("Failed to fetch menu data.");
        }

        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error("Error fetching user data:", error);
    }
}

export const GetUserMenuItems = async (UserName?: string): Promise<MenuItemDetails> => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/GetMenuItems/" + UserName;

        const response = await fetch(restUrl, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        });

        if (!response.ok) {
            throw new Error("Failed to fetch user specific menu data.");
        }

        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error("Error fetching user data:", error);
    }
}

export const GetUsersAdmin = async (UserName?: string): Promise<MangeUsersDTOV2> => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/GetUsersAdmin";
        if (UserName)
            restUrl += "/" + UserName;

        const response = await fetch(restUrl, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        });

        if (!response.ok) {
            throw new Error("Failed to fetch user data.");
        }

        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error("Error fetching user data:", error);
    }
}


export const ToggleUserRights = async (userName: string, rightType: string): Promise<boolean> => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/UpdateUserRights/" + userName;
        var premission = new PermissionRequest();
        premission.right = rightType;
        var postData: string = JSON.stringify(premission);

        const response = await fetch(restUrl, {
            method: "POST",
            body: postData,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        });

        if (!response.ok) {
            throw new Error("Failed to fetch user data.");
        }
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error("Error fetching user data:", error);
    }
}

export const UpdateUserReportLanguageCode = async (loginId: string, reportLangCode: string): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateReportLangCode/";
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: JSON.stringify({
            LoginId: loginId,
            LanguageCode: reportLangCode
        }),
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const UpdateUserReportRole = async (loginId: string, reportRoleId: number): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateUserReportRole/";
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: JSON.stringify({
            loginId,
            reportRoleId
        }),
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}


export const UpdateMenuLink = async (id: number, link: string): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateMenuLink/";
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: JSON.stringify({
            name: 'MenuName',
            link,
            id
        }),
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}


export const UpdateDisplayOrder = async (id: number, displayOrder: number): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateDisplayOrder/";
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: JSON.stringify({
            name: 'MenuName',
            displayOrder,
            id
        }),
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const UpdateUserDefaultReport = async (userName: string, reportId: string): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateUserDefautReport/" + userName + "/" + reportId;
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const UpdateTenantDefaultReport = async (tenantName: string, reportId: string): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateTenantsDefautReport/" + tenantName + "/" + reportId;
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const AddNewReportRole = async (selectedTenant: string, newRole: string) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/AddTenantRole/";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify({
                tenantName: selectedTenant,
                role: newRole
            })
        });

        if (!response.ok) {
            throw new Error(`Failed to add new role.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}

export const EditReportRole = async (roleId: number, role: string) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/EditTenantRole";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify({
                role,
                roleId
            })
        });

        if (!response.ok) {
            throw new Error(`Failed to add new role.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}


export const DeleteReportRole = async (roleId: number) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/DeleteTenantRole/";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify({
                roleId
            })
        });

        if (!response.ok) {
            throw new Error(`Failed to add new role.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}

export const DeleteMenuItem = async (menuId: number) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/DeleteMenuItem/";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify({
                menuId
            })
        });

        if (!response.ok) {
            throw new Error(`Failed to delete Menu Item: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}

export const CreateMenuItems = async (createMenuItem: any) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/CreateMenuItems/";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify(createMenuItem)
        });

        if (!response.ok) {
            throw new Error(`Failed to delete Menu Item: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}

export const UpdateMenuItems = async (updateMenuItems: any) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/UpdateMenuItems/";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify(updateMenuItems)
        });

        if (!response.ok) {
            throw new Error(`Failed to delete Menu Item: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}



export const readFileContent = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result instanceof ArrayBuffer) {
                resolve(reader.result);
            } else {
                reject(new Error('Failed to read file content'));
            }
        };
        reader.readAsArrayBuffer(file);
    });
};

export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
};



