import MenuIcon from '@mui/icons-material/Menu';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toTitleCase } from './CommonComponents';
import './Header.css';
const AnimatedMenuIcon = motion(MenuIcon);

interface MenuItemProps {
    name: string;
    link?: string;
    level: number;
    children?: MenuItemProps[];
}

interface MenuProps {
    items: MenuItemProps[];
}

const MenuItemComponent = ({ item }: { item: MenuItemProps }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLLIElement>(null);
    const navigate = useNavigate();

    const handleNavigation = (event: any) => {
        event.preventDefault();
        event.stopPropagation(); 
        if (item.link) {
            navigate(item.link);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);


    return (
        <>
            <li ref={menuRef}
                onClick={handleNavigation}
                style={item.level === 2 ? { paddingLeft: '0px', paddingRight: '0px' } : {}}
            >
                <span
                    style={
                        item.level === 2 ? {
                            padding: '0px',
                            textAlign: 'center',
                            fontWeight: '400',
                            fontSize: '16px',
                            fontFamily: 'Barlow',
                            lineHeight: '26px'
                        } : item.level === 1
                            ? {
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                fontFamily: 'Barlow Semi Condensed !important',
                                lineHeight: '26px',
                                fontWeight: '800',
                            }
                            : {
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                fontFamily: 'Barlow',
                                lineHeight: '26px',
                            }
                    }
                >
                    {item.level !== 2 ? item.name : toTitleCase(item.name)}
                </span>
                {
                    item.children?.length > 0 && (
                        <ul
                            style={item.level !== 0 ? {
                                top: '0%',
                                position: 'relative',
                            } : {
                                left: 'revert-layer'
                            }}>
                            {item.children.map((subItem, index) => (
                                <MenuItemComponent key={index} item={subItem} />
                            ))}
                        </ul>
                    )
                }
            </li>
        </>
    );
};

const HeaderMenu = ({ items }: MenuProps) => {

    return (
        <ul className="menu">
            {items.map((item, index) => (
                <MenuItemComponent key={index} item={item} />
            ))}
        </ul>
    );
};


export default HeaderMenu;
