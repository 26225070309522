import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Button from '@mui/material/Button';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { PowerBiLoginRequest } from "../AuthConfig";
import HeaderMenu from './HeaderMenu';
import { MenuItem } from "../models/models";

const LoginMenu = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const { embeddingData, setDisplayMainLoader, isSuperUser } = useContext(AppContext);

    const loginButtonStyles = isAuthenticated ? {
        color: 'white',
        backgroundColor: 'transparent',
        textDecoration: 'none',
        fontWeight: '700',
        fontSize: '16px',
        textTransform: 'uppercase' as 'uppercase',
        fontFamily: "'Barlow', sans-serif",
        zIndex: 4,
        letterSpacing: 'normal',
        "&:hover": {
            backgroundColor: 'transparent',
        },
    } : {
        color: 'white',
        backgroundColor: 'transparent',
        textDecoration: 'none',
        fontWeight: '700',
        fontSize: '16px',
        textTransform: 'uppercase' as 'uppercase',
        fontFamily: "'Barlow', sans-serif",
        letterSpacing: 'normal',
        zIndex: 4,
        "&:hover": {
            backgroundColor: 'transparent',
        },
    }

    const loginUser = () => {
        instance.loginPopup(PowerBiLoginRequest);
    };

    const logoutUser = () => {
        setDisplayMainLoader(false);
        embeddingData.setRedirectUserToReport(false);
        navigate("/");
        instance.logoutPopup();
    };

    const permissionBasedObjects: MenuItem[] = [
        {
            id: -1000,
            name: 'SETTINGS',
            level: 0,
            parentId: null,
            link: null,
            displayOrder: 1000,
            isAssigned: true
        },
        {
            id: -1001,
            name: 'USER MANAGEMENT',
            level: 1,
            parentId: -1000,
            link: '/usermanagement',
            displayOrder: 0,
            isAssigned: true
        },
        {
            id: -1002,
            name: 'CONTENT MANAGEMENT',
            level: 1,
            parentId: -1000,
            link: '/contentmanagement',
            isAssigned: true,
            displayOrder: 1
        }
    ];

    if (isAuthenticated) {

        const menuMap: { [id: number]: MenuItem } = {};
        const rootItems: MenuItem[] = [];

        permissionBasedObjects.forEach(item => {
            menuMap[item.id] = { ...item, children: [] };
        });

        permissionBasedObjects.forEach(item => {
            if (item.parentId != null && menuMap[item.parentId]) {
                menuMap[item.parentId].children!.push(menuMap[item.id]);
            } else {
                rootItems.push(menuMap[item.id]);
            }
        });

        const sortItems = (items: MenuItem[]) => {
            items.sort((a, b) => a.displayOrder - b.displayOrder);
            items.forEach(item => {
                if (item.children && item.children.length > 0) {
                    sortItems(item.children);
                }
            });
        };


        return (
            <>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    {isSuperUser && (
                        <>
                            <HeaderMenu items={rootItems} />
                            <Button
                                sx={{
                                    ...loginButtonStyles,
                                    marginRight: '18px'
                                }}
                                disableElevation
                            >
                                v2.3.0
                            </Button>
                        </>
                    )}
                    <Button
                        sx={loginButtonStyles}
                        disableElevation
                        onClick={() => {
                            logoutUser();
                        }}
                    >
                        Logout
                    </Button>
                </div >
            </>
        );
    }
    else {
        return (
            <Button
                onClick={loginUser}
                sx={loginButtonStyles}
            >Login
            </Button>
        );
    }
}

export default LoginMenu;
