import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationContainer = () => {

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="dark"
                toastStyle={{ backgroundColor: '#0d4557' }}
                bodyStyle={{
                    backgroundColor: '#0d4557', fontSize: '16px',
                    fontFamily: 'Barlow'
                }}
            />
        </>
    )
}

export default NotificationContainer;