import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config Azure AD app setting to be passed to Msal on creation
const TenantId = "common";
const ClientId = "" + process.env.REACT_APP_AUTH_CLIENTID + "";

export const msalConfig: Configuration = {
    auth: {
        clientId: ClientId,
        authority: "https://login.microsoftonline.com/" + TenantId,
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }
};

export const userPermissionScopes: string[] = [
    "api://" + ClientId + "/Reports.Embed"
]

export const PowerBiLoginRequest: PopupRequest = {
    scopes: userPermissionScopes
};