import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { MenuItem } from '../App/models/models';
import { AppContext } from "./AppContext";
import PageLayout from './components/PageLayout';
import { AssignedReports, PowerBiReport } from './models/models';
import { GetEmbeddingData, GetUserReports } from './services/AppOwnsDataWebApi';

const App = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [tenantName, setTenantName] = useState<string>(null);
    const [pageLoadReportId, setPageLoadReportId] = useState('');
    const [reports, setReports] = useState<PowerBiReport[]>(null);
    const [user, setUser] = useState<string>(null);
    const [redirectUserToReport, setRedirectUserToReport] = useState<boolean>(null);
    const [workspaceArtifactsLoading, setWorkspaceArtifactsLoading] = useState<boolean>(null);
    const [displayMainPageLoader, setDisplayMainPageLoader] = useState<boolean>(false);
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [assignedUserReports, setAssignedUserReports] = useState<AssignedReports[]>(null);
    const [reportLanguageCode, setReportLanguageCode] = useState<string>(null);
    const [menuItems, setMenuItems] = useState([]);

    const refreshEmbeddingData = () => {
        //const refreshEmbeddingDataAsync = async () => {
        //    let viewModel = await GetEmbeddingData();
        //    let userReports = await GetUserReports(accounts[0].username);
        //    let filteredReports = viewModel?.reports?.filter(x => userReports?.assignedReports?.map(y => y.id)?.indexOf(x.id) !== -1 && x.name?.substring(0, 1) != "0" && x.name?.substring(0, 6) != "Report" && x.name?.substring(0, 5) != "Usage");
        //    if (userReports.assignedReports.length > 0) {
        //        let assignedDefaultReport = userReports.assignedReports.filter(x => x.isDefaultReport === true);
        //        assignedDefaultReport.length > 0 ? setPageLoadReportId(assignedDefaultReport[0].id) : setPageLoadReportId(userReports.assignedReports[0].id);
        //    }
        //    setReports(filteredReports ? filteredReports : []);
        //};
        //refreshEmbeddingDataAsync();
    };

    useEffect(() => {
        const fetchPageLoadReport = async () => {
            if (assignedUserReports && assignedUserReports.length > 0) {
                let assignedDefaultReport = assignedUserReports.filter(x => x.isDefaultReport === true);
                if (assignedDefaultReport.length > 0) {
                    setPageLoadReportId(assignedDefaultReport[0].link.replace('reports/', ''))

                } else {
                    setPageLoadReportId(reports[0].id);
                }
            }
            setWorkspaceArtifactsLoading(false);
        };
        fetchPageLoadReport();
    }, [reports])


    useEffect(() => {
        if (!isAuthenticated)
            return;

        const getEmbeddingDataAsync = async () => {
            setWorkspaceArtifactsLoading(true);
            let viewModel = await GetEmbeddingData();

            //User Reports
            let userReports = await GetUserReports(accounts[0].username);
            let filteredReports = viewModel?.reports?.filter(x => userReports?.assignedReports);

            setTenantName(viewModel.tenantName);
            setAssignedUserReports(userReports?.assignedReports || []);
            setReportLanguageCode(viewModel.reportLanguageCode);
            setReports(filteredReports || []);

            let flatMenuItems = viewModel.menuItems.filter(obj1 => userReports.assignedReports.some(x => x.menuId == obj1.id));
            const menuMap: { [id: number]: MenuItem } = {};
            const rootItems: MenuItem[] = [];

            // Initialize the menuMap with items and empty children arrays
            flatMenuItems.forEach(item => {
                menuMap[item.id] = { ...item, children: [] };
            });

            // Building hierarchy by assigning children based on parentId
            flatMenuItems.forEach(item => {
                if (item.parentId != null && menuMap[item.parentId]) {
                    // Item has a parent; add it to the parent's children array
                    menuMap[item.parentId].children!.push(menuMap[item.id]);
                } else {
                    // No parentId means it's a root item
                    rootItems.push(menuMap[item.id]);
                }
            });

            // Recursive function to sort items by displayOrder, including their children
            const sortItems = (items: MenuItem[]) => {
                items.sort((a, b) => a.displayOrder - b.displayOrder);
                items.forEach(item => {
                    if (item.children && item.children.length > 0) {
                        sortItems(item.children);
                    }
                });
            };
            sortItems(rootItems);
            setMenuItems(rootItems);
            setUser(accounts[0].name);
            setIsSuperUser(viewModel.isSuperUser);
            setRedirectUserToReport(true);
        }

        try {
            getEmbeddingDataAsync();
        }
        catch (e) {
            console.log("Exception", e);
        }

    }, [isAuthenticated]);

    return (
        <AppContext.Provider value={{
            embeddingData: {
                tenantName: tenantName,
                reports: reports,
                pageLoadReportId: pageLoadReportId,
                user: user,
                workspaceArtifactsLoading: workspaceArtifactsLoading,
                redirectUserToReport: redirectUserToReport,
                setRedirectUserToReport: setRedirectUserToReport,
                reportLanguageCode: reportLanguageCode,
                setReportLanguageCode: setReportLanguageCode,
            },
            refreshEmbeddingData: refreshEmbeddingData,
            setDisplayMainLoader: setDisplayMainPageLoader,
            displayMainLoader: displayMainPageLoader,
            isSuperUser: isSuperUser,
            menuItems: menuItems,
        }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CssBaseline />
                <BrowserRouter>
                    <PageLayout />
                </BrowserRouter>
            </LocalizationProvider>
        </AppContext.Provider>
    )
}

export default App;