import Box from '@mui/material/Box';
import { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import NotificationContainer from './NotificationContainer';
import Home from './pages/Home';
import Header from './Header';
import ManagementPlaceholder from './placeholders/ManagementPlaceholder';

const UserManagement = lazy(() => import('./pages/UserManagement'));
const ContentManagement = lazy(() => import('./pages/ContentManagement'));
const PageNotFound = lazy(() => import('./PageNotFound'));
const Report = lazy(() => import('./pages/Report'));

const box1Styles = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
    maxHeight: '100vh'
}

const box2Styles = {
    flexGrow: 1,
    overflow: 'auto',
}

const PageLayout = () => {

    return (
        <Box sx={box1Styles}>
            <NotificationContainer />
            <Header></Header>
            <Box sx={box2Styles}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="reports/:id" element={
                        <Suspense><Report /></Suspense>
                    } />
                    <Route path="usermanagement" element={
                        <Suspense fallback={<ManagementPlaceholder />}>
                            <UserManagement />
                        </Suspense>
                    } />
                    <Route path="contentmanagement" element={
                        <Suspense fallback={<ManagementPlaceholder />}>
                            <ContentManagement />
                        </Suspense>
                    } />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Box >
        </Box >
    );
}

export default PageLayout;
