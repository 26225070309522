import { useIsAuthenticated } from "@azure/msal-react";
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { lazy, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import Header from '../Header';


const DataLoading = lazy(() => import('./../DataLoading'));

const highlightStyles = {
    color: '#30dea3',
    fontWeight: "600"
}

const subTextStyles = {
    textAlign: 'center',
    paddingBottom: '4%'
}

const alertStyles = {
    border: 1,
    padding: 2,
    mx: 2
}

const welcomeTextStyles = {
    my: 3
}

const Home = () => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const { embeddingData, setDisplayMainLoader } = useContext(AppContext);
    const videoBackgroundStyle: React.CSSProperties = {
        position: 'relative',
        width: '100%',
        height: '100vh', 
        overflow: 'hidden',
    };

    const videoStyle: React.CSSProperties = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        minWidth: '100%',
        minHeight: '100%',
        width: 'auto',
        height: 'auto',
        opacity: 1, 
        transform: 'translate(-50%, -50%)',
        zIndex: -1, 
    };

    const contentStyle: React.CSSProperties = {
        position: 'relative',
        zIndex: 1, 
        textAlign: 'center', 
        color: 'white', 
    };

    const imgStyle: React.CSSProperties = {
        height: '116.3px', 
        width: '100%', 
        maxWidth: '1102.72px', 
        marginBottom: '20px', 
        zIndex: 4
    };

    const wrapperStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
    };

    if (!isAuthenticated) {
        return (
            <Container maxWidth={false} style={{ padding: '0px' }}>
                <div style={videoBackgroundStyle}>
                    <Header></Header>
                    <div style={wrapperStyle}>
                        <div className="video-color-overlay row-bg-layer"
                            style={{
                                opacity: 0.7,
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                WebkitBackfaceVisibility: 'hidden',
                                top: 0,
                                left: 0,
                                paddingBottom: 0,
                                zIndex: 3,
                                backgroundColor: 'black'
                            }}></div>
                        <video className="nectar-video-bg"
                            preload="auto"
                            autoPlay loop muted playsInline style={videoStyle}>
                            <source src="https://travelheartfamily.com/wp-content/uploads/2024/04/Primo_Family_BG4.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <img
                            fetchPriority="high"
                            decoding="async"
                            className="img-with-animation skip-lazy"
                            data-delay="0"
                            style={imgStyle}
                            src="https://travelheartfamily.com/wp-content/uploads/2023/09/Travelheart-Family-Logo-Neg-1.png"
                            alt="Travelheart Family Logo"
                            srcSet="https://travelheartfamily.com/wp-content/uploads/2023/09/Travelheart-Family-Logo-Neg-1.png 2048w, https://travelheartfamily.com/wp-content/uploads/2023/09/Travelheart-Family-Logo-Neg-1-300x32.png 300w, https://travelheartfamily.com/wp-content/uploads/2023/09/Travelheart-Family-Logo-Neg-1-1024x108.png 1024w, https://travelheartfamily.com/wp-content/uploads/2023/09/Travelheart-Family-Logo-Neg-1-768x81.png 768w, https://travelheartfamily.com/wp-content/uploads/2023/09/Travelheart-Family-Logo-Neg-1-1536x162.png 1536w"
                            sizes="(max-width: 2048px) 100vw, 2048px"
                        />
                    </div>

                </div>
            </Container>
        )
    }

    if (isAuthenticated && embeddingData.user && !embeddingData.tenantName) {
        return (
            <Container maxWidth={false} style={{ padding: '0px' }}>
                <Typography variant='h5' component="h2" sx={welcomeTextStyles} >Welcome to <span style={highlightStyles}>DBIZ</span> Portal</Typography>
                <Alert sx={alertStyles} severity='warning' >You user account has not been assigned to a tenant. You will
                    not have access to any reports until your user account has been assigned to a tenant.</Alert>
            </Container>
        )
    }

    if (embeddingData.workspaceArtifactsLoading) {
        setDisplayMainLoader(true);
        return <DataLoading />
    }

    if (isAuthenticated && embeddingData.workspaceArtifactsLoading === false && embeddingData.redirectUserToReport === true) {
        setDisplayMainLoader(false);
        if (embeddingData.reports.length > 0) {
            navigate("/reports/" + embeddingData.pageLoadReportId);
        }
        else {
            return (
                <Container maxWidth={false}>
                    <Typography variant='h5' component="h2" sx={welcomeTextStyles} >Welcome to <span style={highlightStyles}>DBIZ</span> Portal</Typography>
                    <Alert sx={alertStyles} severity='warning' >Your user account does not have any reports assigned.</Alert>
                </Container>
            )
        }
    }
}

export default Home;