
import { createContext, Dispatch, SetStateAction } from 'react';

import { PowerBiDataset, PowerBiReport, MenuItem } from './models/models';

export interface EmbeddingData {
    tenantName: string;
    reports: PowerBiReport[];
    pageLoadReportId: string;
    user: string;
    workspaceArtifactsLoading?: boolean;
    redirectUserToReport: boolean,
    setRedirectUserToReport: Dispatch<SetStateAction<boolean>>,
    reportLanguageCode: string;
    setReportLanguageCode: Dispatch<SetStateAction<string>>
}

export const EmbeddingDataDefaults: EmbeddingData = {
    tenantName: null,
    reports: [],
    pageLoadReportId: '',
    user: null,
    workspaceArtifactsLoading: false,
    redirectUserToReport: false,
    setRedirectUserToReport: null,
    reportLanguageCode: 'en-US',
    setReportLanguageCode: null,
}

export interface AppContextProps {
    embeddingData: EmbeddingData;
    refreshEmbeddingData: () => void;
    setDisplayMainLoader: Dispatch<SetStateAction<boolean>>;
    displayMainLoader: boolean,
    isSuperUser: boolean,
    menuItems: MenuItem[]
}

export const AppContext = createContext<AppContextProps>({
    embeddingData: EmbeddingDataDefaults,
    refreshEmbeddingData: () => { },
    setDisplayMainLoader: () => { },
    displayMainLoader: false,
    isSuperUser: false,
    menuItems: []
});


