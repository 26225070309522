
export class PowerBiReport {
    id: string;
    name: string;
    datasetId: string;
    embedUrl: string;
    reportType: string;
}

export class PowerBiDataset {
    id: string;
    name: string;
    createReportEmbedURL: string;
}

class PowerBiTenant {
    name: string;
}

export class MenuItem {
    id: number;
    name: string;
    level: number;
    parentId?: number | null;
    link?: string;
    displayOrder: number;
    children?: MenuItem[];
    isAssigned: boolean = false;
}

export class ViewModel {
    tenantName: string;
    reports: PowerBiReport[];
    embedToken: string;
    embedTokenExpiration: string;
    user: string;
    isSuperUser: boolean;
    reportLanguageCode: string;
    reportBackgroundColor: string;
    menuItems: MenuItem[];
}
export class EmbedTokenResult {
    token: string;
    expiration: string;
}
export class User {
    LoginId: string;
    UserName: string;
    isSuperUser: boolean;
    isDbizAdmin: boolean;
}

export class Reports {
    reportID: string;
    reportName: string;
    IsActive: boolean;
    isAssigned: boolean = false;
    isDefaultReport: boolean = false;
}

export class AssignedReports {
    id: number;
    menuId: number;
    isDefaultReport: boolean;
    userID: string;
    link: string;
}

export class UserReports {
    allReports: MenuItem[];
    assignedReports: AssignedReports[];
}

export class MangeUsersDTO {
    users: ManageUserDetails[];
    tenantRoles: TenantRoles[]
}

export class MangeUsersDTOV2 {
    users: ManageUserDetails[];
    tenants: PowerBiTenant[];
    tenantRoles: TenantRoles[];
}

export class UserRoles {
    loginId: string;
    role: string;
}

export class PermissionRequest {
    userRoles?: UserRoles[];
    right?: string;
    reports?: string[];
}

export class ValidationPermissionsRequest {
    userToCheck: string;
    permissionsToCheck: string[];
}

export class ManageUserDetails {
    loginId: string;
    userName: string;
    canEdit: boolean;
    canCreate: boolean;
    isSuperUser: boolean | null;
    role: string;
    created: string;
    lastLogin: string;
    tenantName: string;
    reportLanguageCode: string;
}

export class TenantRoles {
    id: number;
    tenantName: string;
    role: string;
}

export class Dropdowns {
    statusID: number;
    statusName: string;
    context: string;
}

export interface MangeTenantUsersRowData {
    loginId: string;
    userName: string;
    lastLogin: string;
    roleId: number;
    canEdit: boolean;
    canCreate: boolean,
    isSuperUser: boolean,
    isDbizAdmin: boolean,
    tenantName: string;
    reportLanguageCode: string;
}

export interface ManageMenuItemsData {
    id: number;
    name: string;
    level: number;
    parentId?: number | null;
    link?: string;
    displayOrder: number;
}

export interface MenuItemDetails {
    menuItems: MenuItem[],
    menuLevels: number[]
}

export interface TenantReportCodesRowData {
    Id: string;
    role: string;
}

export interface MangeUsersRowData {
    loginId: string;
    userName: string;
    lastLogin: string;
    roleId: number;
    canEdit: boolean;
    canCreate: boolean,
    isSuperUser: boolean,
    reportLanguageCode: string;
}

export interface MangeMenuItemAssignmentRowData {
    id: string;
    name: string;
    link: string;
    isAssigned: boolean;
    isDefaultReport: boolean;
}

export interface ManageReportSortRowData {
    reportName: string;
    reportID: string;
    assignmentCount: number;
    defaultReportId: string;
}
